import {JSX, ReactNode, useState} from "react";
import dummyImageSolar from '../assets/rs-solar-thumbnail.png';
import montageSet from '../assets/montage-set.png';
import p1Switch from '../assets/p1-switch.png';
import usbMicroB from '../assets/usb-micro-b.png';
import usbMicroC from '../assets/usb-c.jpg';
import hwP1Meter from '../assets/hw-p1-meter.png';
import {RedLinkButton} from "./HomePage";
import {CheckMarkBoxes} from "../components/CheckMarkBoxes";

export default function ExtraShopPage(): JSX.Element {
    return (
        <div className="main_container">
            <div style={{
                backgroundImage: `url(${dummyImageSolar})`, backgroundSize: "cover", height: "542px", display: "flex",
                flexDirection: "column", justifyContent: "flex-end"
            }}>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        maxWidth: "900px",
                        paddingLeft: "20px"
                    }}>
                        <h1 style={{fontSize: "45px", textShadow: "1px 1px 3px BLACK"}}>Toebehoren</h1>
                        <label style={{fontSize: "20px", textShadow: "1px 1px 3px BLACK"}}>

                        </label>
                        <div style={{display: "flex", flexDirection: "row"}}>
                        </div>
                    </div>
                </div>
                <CheckMarkBoxes
                    titles={["Bespaar op gasverbruik", "Meer zelfvoorzienend", "Verdien aan zonne energie"]}/>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingTop: "10px"
            }}>
                <article style={{width: "100%", maxWidth: "900px"}}>
                    <span style={{color: 'red'}}>*</span> Let op alle prijzen zijn exclusief eventuele verzend en
                    installatie kosten.
                </article>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "10px",
                    boxShadow: "inset 0px 0px 2px 0px black"
                }}/>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                height: "10px",
                boxShadow: "inset 0px 0px 2px 0px black"
            }}/>
            <Product title="Montageset"
                     to={"mailto:sales@rs-solarenergy.com?subject=Montage set&body=Geachte RS SolarEnergy,%0D%0A%0D%0AIk schrijf u om een offerte aan te vragen voor het volgende product:%0D%0AProduct: Montageset - aantal: 1x"}>
                <img style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    borderRadius: "10px",
                    width: "100%",
                    maxWidth: "600px"
                }} src={montageSet} alt=""/>
                <div style={{width: "100%", maxWidth: "900px"}}>
                    <b>Specificaties:</b>
                    <ul>
                        <li>Duopower 10x50: 4 Stuks</li>
                        <li>Houtdraadbout 10 x 50 mm: 4 stuks</li>
                        <li>Sluitringen M10: 4 stuks</li>
                    </ul>
                    <b>Prijs:</b><br/>
                    €4.00,- <i>incl. btw</i>
                </div>
            </Product>
            <Product title="Actieve P1 splitter"
                     to={"mailto:sales@rs-solarenergy.com?subject=Actieve P1 splitter&body=Geachte RS SolarEnergy,%0D%0A%0D%0AIk schrijf u om een offerte aan te vragen voor het volgende product:%0D%0AProduct: Actieve P1 splitter - aantal: 1x"}>
                <img style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    borderRadius: "10px",
                    width: "100%",
                    maxWidth: "600px"
                }} src={p1Switch} alt=""/>
                <div style={{width: "100%", maxWidth: "900px"}}>
                    <b>Interface:</b>
                    <ul>
                        <li>P1-5V in</li>
                        <li>P1-5V uit (3x)</li>
                        <li>USB-C (5V 1A)</li>
                    </ul>
                    <b>Prijs:</b><br/>
                    €27.95,- <i>incl. btw</i>
                </div>
            </Product>
            <Product title="Micro USB-B adapter"
                     to={"mailto:sales@rs-solarenergy.com?subject=Micro USB-B adapter&body=Geachte RS SolarEnergy,%0D%0A%0D%0AIk schrijf u om een offerte aan te vragen voor het volgende product:%0D%0AProduct: Micro USB-B adapter - aantal: 1x"}>
                <img style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    borderRadius: "10px",
                    width: "100%",
                    maxWidth: "600px"
                }} src={usbMicroB} alt=""/>
                <div style={{width: "100%", maxWidth: "900px"}}>
                    <b>Specificaties:</b>
                    <ul>
                        <li>Aansluitspanning: 230 Volt</li>
                        <li>Maximale uitgangsstroom: 1 A</li>
                        <li>Maximale uitgangsvoltage: 5 Volt</li>
                        <li>Maximale uitgangsvermogen: 5 Watt</li>
                    </ul>
                    <b>Prijs:</b><br/>
                    €12.50,- <i>incl. btw</i>
                </div>
            </Product>
            <Product title="Micro USB-C adapter"
                     to={"mailto:sales@rs-solarenergy.com?subject=Micro USB-C adapter&body=Geachte RS SolarEnergy,%0D%0A%0D%0AIk schrijf u om een offerte aan te vragen voor het volgende product:%0D%0AProduct: Micro USB-C adapter - aantal: 1x"}>
                <img style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    borderRadius: "10px",
                    width: "100%",
                    maxWidth: "600px"
                }} src={usbMicroC} alt=""/>
                <div style={{width: "100%", maxWidth: "900px"}}>
                    <b>Specificaties:</b>
                    <ul>
                        <li>Aansluitspanning: 230 Volt</li>
                        <li>Maximale uitgangsvermogen: 20 Watt</li>
                    </ul>
                    <b>Prijs:</b><br/>
                    €8.95,- <i>incl. btw</i>
                </div>
            </Product>
            <Product title="HomeWizard P1 meter"
                     to={"mailto:sales@rs-solarenergy.com?subject=HomeWizard P1 meter&body=Geachte RS SolarEnergy,%0D%0A%0D%0AIk schrijf u om een offerte aan te vragen voor het volgende product:%0D%0AProduct: HomeWizard P1 meter - aantal: 1x"}>
                <img style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    borderRadius: "10px",
                    width: "100%",
                    maxWidth: "600px"
                }} src={hwP1Meter} alt=""/>
                <div style={{width: "100%", maxWidth: "900px"}}>
                    <b>Specificaties:</b>
                    <ul>
                        <li>Verbinding: Wi-Fi 2.4GHz b/g/n</li>
                        <li>Energieverbruik: &lt; 1 Watt</li>
                    </ul>
                    <b>Prijs:</b><br/>
                    €29.95,- <i>incl. btw</i>
                </div>
            </Product>
        </div>
    );
};

type productProps = {
    title?: string;
    description?: string;
    to?: string;
    children?: ReactNode;
    disableButton?: boolean;
}

export function Product({title, description, children, to, disableButton}: productProps) {
    return (
        <>
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                paddingLeft: "20px",
                paddingRight: "20px"
            }}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <h1 style={{color: "var(--primary-text-color)"}}>{title}</h1>
                    <label style={{color: "var(--primary-text-color)", fontSize: "22px"}}>{description}</label>
                </div>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    {
                        children
                    }
                </div>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                    {!disableButton && <RedLinkButton name={"OFFERTE AANVRAGEN"} to={to}/>}
                </div>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                height: "10px",
                boxShadow: "inset 0px 0px 2px 0px black",
                marginTop: "10px"
            }}/>
        </>
    )
}