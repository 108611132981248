import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import HomePage from "./pages/HomePage";
import Menu from "./components/common/Menu";
import {createGraphiQLFetcher} from '@graphiql/toolkit';
import {GraphiQL} from 'graphiql';
import ShopPage from "./pages/ShopPage";
import HowDoesItWorkPage from "./pages/HowDoesItWorkPage";
import InstallationPage from "./pages/InstallationPage";
import ControlPage from "./pages/ControlPage";
import ArticleSolarBoiler from "./pages/ArticleSolarBoiler";
import Test from "./pages/Test";
import ExtraShopPage from "./pages/ExtraShopPage";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const fetcher = createGraphiQLFetcher({
    url: `${process.env.REACT_APP_API_URL}/graphql`,
    headers: {"Authorization": `Bearer ${localStorage.jsw_token}`}
});

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="information" element={<Test/>}/>
                <Route path="*" element={<Menu/>}>
                    <Route path="*" element={<HomePage/>}/>
                    <Route path="winkel" element={<ShopPage/>}/>
                    <Route path="toebehoren" element={<ExtraShopPage/>}/>
                    <Route path="werkwijze" element={<HowDoesItWorkPage/>}/>
                    <Route path="installatie" element={<InstallationPage/>}/>
                    <Route path="artikels/zonnestroomboiler" element={<ArticleSolarBoiler/>}/>
                </Route>
                <Route path="admin/graphiql" element={<GraphiQL fetcher={fetcher}/>}/>
                <Route path="control" element={<ControlPage/>}/>
            </Routes>
        </BrowserRouter>
    );
}

root.render(
    <App/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
