import {JSX, useEffect, useState} from "react";
import SystemInformationController from "../controllers/SystemInformationController";
import ISystemInformation from "../models/ISystemInformation";
import Table, {TableColumn, TableHeader, TableHeaderItem, TableRow} from "../components/common/Table";

export default function Test(): JSX.Element {
    const [systemInformation, setSystemInformation] = useState<ISystemInformation>();

    useEffect(() => {
        SystemInformationController.get().then((companyData: ISystemInformation | null) => {
            companyData && setSystemInformation(companyData);
        });
    }, []);

    return (
        <div className="main_container">
            {
                systemInformation &&
                <div style={{backgroundColor: "white"}}>
                    <h1 style={{color: '#3b4b68e6'}}>RSS-v1</h1>
                    <h2 style={{color: '#3b4b68e6'}}>Control device</h2>
                    <table>
                        <tbody>
                        <tr>
                            <th>Information</th>
                            <th>Value</th>
                            <th></th>
                        </tr>
                        <tr>
                            <td>Firmware version</td>
                            <td style={{paddingLeft: '5px'}}>{systemInformation.firmware_version}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Operation time</td>
                            <td style={{paddingLeft: '5px'}}>{systemInformation.system_operation_time_ms}</td>
                            <td>ms</td>
                        </tr>
                        <tr>
                            <td>Control device</td>
                            <td style={{paddingLeft: '5px'}}>{systemInformation.active_control_device}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Status control device</td>
                            <td style={{paddingLeft: '5px'}}>{systemInformation.active_control_device_status}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Active power</td>
                            <td style={{paddingLeft: '5px'}}>{systemInformation.active_power_w}</td>
                            <td style={{paddingLeft: '5px'}}>Watt</td>
                        </tr>
                        </tbody>
                    </table>
                    <h2 style={{color: '#3b4b68e6'}}>Boiler information</h2>
                    <table>
                        <tbody>
                        <tr>
                            <th>Information</th>
                            <th>Value</th>
                            <th></th>
                        </tr>
                        <tr>
                            <td>Boiler 1</td>
                            <td style={{paddingLeft: '5px'}}>{systemInformation.active_power_boiler1_w} /{systemInformation.active_power_sv_boiler1_w}</td>
                            <td style={{paddingLeft: '5px'}}>Watt</td>
                        </tr>
                        <tr>
                            <td>Boiler 1 charge time</td>
                            <td style={{paddingLeft: '5px'}}>{systemInformation.charge_time_boiler1_ms} /{systemInformation.charge_time_sv_boiler1_ms}</td>
                            <td style={{paddingLeft: '5px'}}>ms</td>
                        </tr>
                        <tr>
                            <td>Boiler 1 status</td>
                            <td style={{paddingLeft: '5px'}}>{systemInformation.control_status_boiler1}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Boiler 2</td>
                            <td style={{paddingLeft: '5px'}}>{systemInformation.active_power_boiler2_w} /{systemInformation.active_power_sv_boiler2_w}</td>
                            <td style={{paddingLeft: '5px'}}>Watt</td>
                        </tr>
                        <tr>
                            <td>Boiler 2 charge time</td>
                            <td style={{paddingLeft: '5px'}}>{systemInformation.charge_time_boiler2_ms} /{systemInformation.charge_time_sv_boiler2_ms}</td>
                            <td style={{paddingLeft: '5px'}}>ms</td>
                        </tr>
                        <tr>
                            <td>Boiler 2 status</td>
                            <td style={{paddingLeft: '5px'}}>{systemInformation.control_status_boiler2}</td>
                            <td></td>
                        </tr>
                        </tbody>
                    </table>
                    <h2 style={{color: '#3b4b68e6'}}>Ethernet information</h2>
                    <table>
                        <tbody>
                        <tr>
                            <th>Information</th>
                            <th>Value</th>
                            <th></th>
                        </tr>
                        <tr>
                            <td>Link-status</td>
                            <td style={{paddingLeft: '5px'}}>{systemInformation.ethernet_link_status}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Hardware-status</td>
                            <td style={{paddingLeft: '5px'}}>{systemInformation.ethernet_hardware_status}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>IP</td>
                            <td style={{paddingLeft: '5px'}}>{systemInformation.ethernet_ip}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Gateway</td>
                            <td style={{paddingLeft: '5px'}}>{systemInformation.ethernet_gw}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>SubnetMask</td>
                            <td style={{paddingLeft: '5px'}}>{systemInformation.ethernet_sm}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>DNS</td>
                            <td style={{paddingLeft: '5px'}}>{systemInformation.ethernet_ds}</td>
                            <td></td>
                        </tr>
                        </tbody>
                    </table>
                    <h2 style={{color: '#3b4b68e6'}}>Wi-Fi information</h2>
                    <table>
                        <tbody>
                        <tr>
                            <th>Information</th>
                            <th>Value</th>
                            <th></th>
                        </tr>
                        <tr>
                            <td>Hardware-status</td>
                            <td style={{paddingLeft: '5px'}}>{systemInformation.wifi_status}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>SSID</td>
                            <td style={{paddingLeft: '5px'}}>{systemInformation.wifi_ssid}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>RSSI</td>
                            <td style={{paddingLeft: '5px'}}>{systemInformation.wifi_rssi}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Mode</td>
                            <td style={{paddingLeft: '5px'}}>{systemInformation.wifi_mode}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Channel</td>
                            <td style={{paddingLeft: '5px'}}>{systemInformation.wifi_channel}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>IP</td>
                            <td style={{paddingLeft: '5px'}}>{systemInformation.wifi_ip}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Gateway</td>
                            <td style={{paddingLeft: '5px'}}>{systemInformation.wifi_gw}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>SubnetMask</td>
                            <td style={{paddingLeft: '5px'}}>{systemInformation.wifi_sm}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>DNS</td>
                            <td style={{paddingLeft: '5px'}}>{systemInformation.wifi_ds}</td>
                            <td></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            }
        </div>
    );
};