import {JSX, ReactNode, useState} from "react";
import dummyImageSolar from '../assets/rs-solar-thumbnail.png';
import rsSolar1Boiler from '../assets/rs-solar-product.png';
import rsSolarController from '../assets/rs-solar-product-controller.png';
import rsSolarController3Fases from '../assets/rs-solar-product-3fases.png';
import rsSolarControllerWireless from '../assets/rs-solar-product-draadloos.png';
import dynamicEnergyContract from '../assets/dynamic_contract.png';
import ceSticker from '../assets/CE-Sticker Recht.png';
import {RedLinkButton} from "./HomePage";
import {CheckMarkBoxes} from "../components/CheckMarkBoxes";

export default function ShopPage(): JSX.Element {
    return (
        <div className="main_container">
            <div style={{
                backgroundImage: `url(${dummyImageSolar})`, backgroundSize: "cover", height: "542px", display: "flex",
                flexDirection: "column", justifyContent: "flex-end"
            }}>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        maxWidth: "900px",
                        paddingLeft: "20px"
                    }}>
                        <h1 style={{fontSize: "45px", textShadow: "1px 1px 3px BLACK"}}>Welkom in onze Webshop</h1>
                        <label style={{fontSize: "20px", textShadow: "1px 1px 3px BLACK"}}>

                        </label>
                        <div style={{display: "flex", flexDirection: "row"}}>
                        </div>
                    </div>
                </div>
                <CheckMarkBoxes
                    titles={["Bespaar op gasverbruik", "Meer zelfvoorzienend", "Verdien aan zonne energie"]}/>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "20px"
            }}>
                <h2 style={{color: "var(--primary-text-color)"}}>Vraag offerte aan voor het gewenste product</h2>
                <article style={{width: "100%", maxWidth: "900px"}}>De meegeleverde boilers zijn uitgevoerd met een
                    draai thermostaat.<br/>
                    De boilers beschikken over een magnesiumanode en emaillen coating.<br/>
                    Zie onze kennisbank voor tips over onderhoud van de anode.<br/>
                    De boiler wordt geleverd met aansluitsnoer en duco-pentec overdruk ventiel.<br/><br/>
                    <b>Installatie</b>: Verticaal<br/>
                    <b>Bediening</b>: Draaiknop thermostaat<br/>
                    <b>Watertank</b>: Geëmailleerd<br/>
                    <b>Nominale druk</b>: 7 Bar<br/>
                    <b>Type warmte element</b>: Droog warmte element
                    <br/><br/>
                    <span style={{color: 'red'}}>*</span> Let op alle prijzen zijn exclusief eventuele verzend en installatie kosten.
                </article>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                height: "10px",
                boxShadow: "inset 0px 0px 2px 0px black"
            }}/>
            <Product title="50L uitvoering"
                     acccessories={true}
                     to={"mailto:sales@rs-solarenergy.com?subject=50L uitvoering&body=Geachte RS SolarEnergy,%0D%0A%0D%0AIk schrijf u om een offerte aan te vragen voor het volgende product:%0D%0AProduct: 50L uitvoering - aantal: 1x"}>
                <img style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    borderRadius: "10px",
                    width: "100%",
                    maxWidth: "600px"
                }} src={rsSolar1Boiler} alt=""/>
                <div style={{width: "100%", maxWidth: "900px"}}>
                    <b>Specificaties RS-Solar Systeem:</b>
                    <ul>
                        <li>Aansluitspanning: 230 Volt</li>
                        <li>Maximale stroom: 16 A</li>
                        <li>Maximaal aan te sluiten vermogen: 3 kW</li>
                        <li>Afmetingen (Lengte x Breedte x Hoogte): 400 x 300 x 210 mm</li>
                        <li>Ethernet port (RJ45)</li>
                        <li>P1 port (RJ12)</li>
                    </ul>
                    <b>Specificaties boiler:</b><br/>
                    <ul>
                        <li>Nominaal vermogen: 2 x 1000 Watt</li>
                        <li>Hoogte x lengte x breedte (mm): 760 x 387 x 410</li>
                        <li>Gewicht: 18,5 kg</li>
                        <li>Volume: 50 liter</li>
                        <li>Energieklasse: C</li>
                    </ul>
                    <b>Complete set RS-Solar Systeem:</b><br/>
                    <ul>
                        <li>1x Elektrische boiler 50L, 2x 1000 Watt</li>
                        <li>1x RS SolarEnergy kast</li>
                        <li>1x RS-SolarEnergy P1 meter</li>
                    </ul>
                    <b>Prijs:</b><br/>
                    €1.570,- <i>incl. btw</i>
                </div>
            </Product>
            <Product title="80L uitvoering"
                     acccessories={true}
                     to={"mailto:sales@rs-solarenergy.com?subject=80L uitvoering&body=Geachte RS SolarEnergy,%0D%0A%0D%0AIk schrijf u om een offerte aan te vragen voor het volgende product:%0D%0AProduct: 80L uitvoering - aantal: 1x"}>
                <div style={{width: "100%", maxWidth: "900px"}}>
                    <b>Specificaties RS-Solar Systeem:</b>
                    <ul>
                        <li>Aansluitspanning: 230 Volt</li>
                        <li>Maximale stroom: 16 A</li>
                        <li>Maximaal aan te sluiten vermogen: 3 kW</li>
                        <li>Afmetingen (Lengte x Breedte x Hoogte): 400 x 300 x 210 mm</li>
                        <li>Ethernet port (RJ45)</li>
                        <li>P1 port (RJ12)</li>
                    </ul>
                    <b>Specificaties boiler:</b><br/>
                    <ul>
                        <li>Nominaal vermogen: 2 x 1000 Watt</li>
                        <li>Hoogte x lengte x breedte (mm): 1125 x 387 x 410</li>
                        <li>Volume: 80 liter</li>
                        <li>Energieklasse: C</li>
                    </ul>
                    <b>Complete set RS-Solar Systeem:</b><br/>
                    <ul>
                        <li>1x Elektrische boiler 80L, 2x 1000 Watt</li>
                        <li>1x RS SolarEnergy kast</li>
                        <li>1x RS-SolarEnergy P1 meter</li>
                    </ul>
                    <b>Prijs:</b><br/>
                    €1.595,- <i>incl. btw</i>
                </div>
                <img style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    borderRadius: "10px",
                    width: "100%",
                    maxWidth: "600px"
                }} src={rsSolar1Boiler} alt=""/>
            </Product>
            <Product title="120L uitvoering (let op pallettransport)"
                     acccessories={true}
                     to={"mailto:sales@rs-solarenergy.com?subject=120L uitvoering&body=Geachte RS SolarEnergy,%0D%0A%0D%0AIk schrijf u om een offerte aan te vragen voor het volgende product:%0D%0AProduct: 120L uitvoering - aantal: 1x"}>
                <div style={{width: "100%", maxWidth: "900px"}}>
                    <b>Specificaties RS-Solar Systeem:</b>
                    <ul>
                        <li>Aansluitspanning: 230 Volt</li>
                        <li>Maximale stroom: 16 A</li>
                        <li>Maximaal aan te sluiten vermogen: 3 kW</li>
                        <li>Afmetingen (Lengte x Breedte x Hoogte): 400 x 300 x 210 mm</li>
                        <li>Ethernet port (RJ45)</li>
                        <li>P1 port (RJ12)</li>
                    </ul>
                    <b>Specificaties boiler:</b><br/>
                    <ul>
                        <li>Nominaal vermogen: 2 x 1000 Watt</li>
                        <li>Hoogte x lengte x breedte (mm): 1160 x 470 x 484</li>
                        <li>Gewicht: 33,5 kg</li>
                        <li>Volume: 120 liter</li>
                        <li>Energieklasse: C</li>
                    </ul>
                    <b>Complete set RS-Solar Systeem:</b><br/>
                    <ul>
                        <li>1x Elektrische boiler 120L, 2000 Watt</li>
                        <li>1x RS SolarEnergy kast</li>
                        <li>1x RS-SolarEnergy P1 meter</li>
                    </ul>
                    <b>Prijs:</b><br/>
                    €1.675,- <i>incl. btw</i>
                </div>
                <img style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    borderRadius: "10px",
                    width: "100%",
                    maxWidth: "600px"
                }} src={rsSolar1Boiler} alt=""/>
            </Product>

            <Product title="150L uitvoering (let op pallettransport)"
                     acccessories={true}
                     to={"mailto:sales@rs-solarenergy.com?subject=150L uitvoering&body=Geachte RS SolarEnergy,%0D%0A%0D%0AIk schrijf u om een offerte aan te vragen voor het volgende product:%0D%0AProduct: 150L uitvoering - aantal: 1x"}>
                <div style={{width: "100%", maxWidth: "900px"}}>
                    <b>Specificaties RS-Solar Systeem:</b>
                    <ul>
                        <li>Aansluitspanning: 230 Volt</li>
                        <li>Maximale stroom: 16 A</li>
                        <li>Maximaal aan te sluiten vermogen: 3 kW</li>
                        <li>Afmetingen (Lengte x Breedte x Hoogte): 400 x 300 x 210 mm</li>
                        <li>Ethernet port (RJ45)</li>
                        <li>P1 port (RJ12)</li>
                    </ul>
                    <b>Specificaties boiler:</b><br/>
                    <ul>
                        <li>Nominaal vermogen: 3000 Watt</li>
                        <li>Hoogte x lengte x breedte (mm): 440 x 1325 x 467</li>
                        <li>Gewicht: 33,5 kg</li>
                        <li>Volume: 150 liter</li>
                        <li>Energieklasse: C</li>
                    </ul>
                    <b>Complete set RS-Solar Systeem:</b><br/>
                    <ul>
                        <li>1x Elektrische boiler 150L, 2000 Watt</li>
                        <li>1x RS SolarEnergy kast</li>
                        <li>1x RS-SolarEnergy P1 meter</li>
                    </ul>
                    <b>Prijs:</b><br/>
                    €1.785,- <i>incl. btw</i>
                </div>
                <img style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    borderRadius: "10px",
                    width: "100%",
                    maxWidth: "600px"
                }} src={rsSolar1Boiler} alt=""/>
            </Product>
            <Product title="RS SolarEnergy besturing"
                     acccessories={true}
                     to={"mailto:sales@rs-solarenergy.com?subject=RS SolarEnergy besturing&body=Geachte RS SolarEnergy,%0D%0A%0D%0AIk schrijf u om een offerte aan te vragen voor het volgende product:%0D%0AProduct: RS SolarEnergy besturing: 1x"}>
                <div style={{width: "100%", maxWidth: "900px"}}>
                    <b>Specificaties RS-Solar Systeem:</b>
                    <ul>
                        <li>Aansluitspanning: 230 Volt</li>
                        <li>Maximale stroom: 16 A</li>
                        <li>Maximaal aan te sluiten vermogen: 3 kW</li>
                        <li>Afmetingen (Lengte x Breedte x Hoogte): 400 x 300 x 210 mm</li>
                        <li>Ethernet port (RJ45)</li>
                        <li>P1 port (RJ12)</li>
                    </ul>
                    <b>Complete set RS-Solar Systeem:</b><br/>
                    <ul>
                        <li>1x RS SolarEnergy kast</li>
                        <li>1x RS-SolarEnergy P1 meter</li>
                    </ul>
                    <b>Prijs:</b><br/>
                    €1.275,- <i>incl. btw</i>
                </div>
                <img style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    borderRadius: "10px",
                    width: "100%",
                    maxWidth: "600px"
                }} src={rsSolarController} alt=""/>
            </Product>
            <Product title="RS SolarEnergy besturing (draadloos)"
                     acccessories={true}
                     to={"mailto:sales@rs-solarenergy.com?subject=RS SolarEnergy besturing&body=Geachte RS SolarEnergy,%0D%0A%0D%0AIk schrijf u om een offerte aan te vragen voor het volgende product:%0D%0AProduct: RS SolarEnergy besturing: 1x"}>
                <div style={{width: "100%", maxWidth: "900px"}}>
                    <b>Specificaties RS-Solar Systeem:</b>
                    <ul>
                        <li>Aansluitspanning: 230 Volt</li>
                        <li>Maximale stroom: 16 A</li>
                        <li>Maximaal aan te sluiten vermogen: 3 kW</li>
                        <li>Afmetingen (Lengte x Breedte x Hoogte): 400 x 300 x 210 mm</li>
                        <li>Ethernet port (RJ45)</li>
                        <li>P1 port (RJ12)</li>
                        <li>Wi-Fi antenne</li>
                    </ul>
                    <b>Complete set RS-Solar Systeem:</b><br/>
                    <ul>
                        <li>1x RS SolarEnergy kast (draadloos)</li>
                        <li>1x RS-SolarEnergy P1 meter</li>
                    </ul>
                    <b>Prijs:</b><br/>
                    €1.320,- <i>incl. btw</i>
                </div>
                <img style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    borderRadius: "10px",
                    width: "100%",
                    maxWidth: "600px"
                }} src={rsSolarControllerWireless} alt=""/>
            </Product>
            <Product title="RS SolarEnergy besturing 3 fases"
                     acccessories={true}
                     to={"mailto:sales@rs-solarenergy.com?subject=RS SolarEnergy besturing&body=Geachte RS SolarEnergy,%0D%0A%0D%0AIk schrijf u om een offerte aan te vragen voor het volgende product:%0D%0AProduct: RS SolarEnergy besturing: 1x"}>
                <div style={{width: "100%", maxWidth: "900px"}}>
                    De <b>RS-SolarEnergy besturing voor 3 fases</b> is ontworpen voor gebruikers met veel zonnepanelen.
                    Met het drie-fasen systeem kunnen apparaten met een hoog vermogen worden verwarmd met behulp van
                    zonne-energie. Hierdoor kan een groot deel van je zonne-energie worden gebruikt om je apparaten te
                    verwarmen.
                    <br/><br/>
                    Het systeem kan het vermogen variëren per fase. Hierdoor kunnen bijvoorbeeld drie afzonderlijke
                    boilers individueel worden aangestuurd.
                    <br/><br/>
                    <b>Te gebruiken voor:</b><br/>
                    <ul>
                        <li>Zwembaden</li>
                        <li>Jacuzzi's</li>
                        <li>Vloer verwarming</li>
                        <li>3 Fase boiler</li>
                        <li>3 Losse boilers</li>
                        <li>Gelijk trekken van de fases</li>
                    </ul>
                    <b>Specificaties RS-Solar Systeem 3 fases:</b>
                    <ul>
                        <li>Aansluitspanning: 400 Volt</li>
                        <li>Maximaal aan te sluiten vermogen per fase: 3 kW</li>
                        <li>Afmetingen (Lengte x Breedte x Hoogte): 400 x 500 x 210 mm</li>
                        <li>Ethernet port (RJ45)</li>
                        <li>P1 port (RJ12)</li>
                    </ul>
                    <b>Complete set RS-Solar Systeem 3 fases:</b><br/>
                    <ul>
                        <li>1x RS SolarEnergy kast 3 fases</li>
                        <li>1x RS-SolarEnergy P1 meter</li>
                    </ul>
                    <b>Prijs:</b><br/>
                    €3.184,- <i>incl. btw</i>
                </div>
                <img style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    borderRadius: "10px",
                    width: "100%",
                    maxWidth: "600px"
                }} src={rsSolarController3Fases} alt=""/>
            </Product>

            <Product title="Dynamisch energie contract besturing"
                     to={"mailto:sales@rs-solarenergy.com?subject=Dynamisch energie contract besturing&body=Geachte RS SolarEnergy,%0D%0A%0D%0AIk schrijf u om een offerte aan te vragen voor het volgende product:%0D%0AProduct: Dynamisch energie contract besturing: 1x%0D%0A%0D%0AMijn code is SN:"}>
                <div style={{width: "100%", maxWidth: "900px"}}>
                    Dankzij de dynamische energie contract besturing kan het <b>RS-Solar
                    Energy-systeem</b> elektriciteit
                    importeren van het net op de momenten dat de prijzen voor jou het gunstigst zijn. Dit betekent dat
                    de boilers zelfs worden opgeladen wanneer de zon niet schijnt, maar bijvoorbeeld wanneer het waait
                    of er een overcapaciteit op het energienet is.<br/><br/>
                    <br/>
                    Voorbeeld van de energiemarktprijzen:
                </div>
                <img style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    borderRadius: "10px",
                    width: "100%",
                    maxWidth: "600px"
                }} src={dynamicEnergyContract} alt=""/>
                <div style={{width: "100%", maxWidth: "900px"}}>
                    <h2 style={{color: "black"}}>Hoe kan ik de functionaliteit activeren?</h2>
                    Om het dynamische energiecontract besturing te activeren voor je <b>RS-Solar Energy
                    systeem</b>, hebben wij een code nodig. De code van je <b>RS-Solar Energy systeem</b> staat op de
                    zijkant
                    van de besturingskast.<br/><br/>
                    In de onderstaande afbeelding is de code van het <b>RS-Solar Energy systeem</b> omcirkeld in blauw:
                    <br/><br/>
                </div>
                <img style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    borderRadius: "10px",
                    width: "100%",
                    maxWidth: "600px"
                }} src={ceSticker} alt=""/>
                <div style={{width: "100%", maxWidth: "900px"}}>
                    <b>Bevat het volgende:</b><br/>
                    <ul>
                        <li>1x Functionaliteit Dynamisch energiecontract besturing</li>
                    </ul>
                    <b>Prijs:</b><br/>
                    €52.50,- <i>incl. btw</i>
                </div>
            </Product>
        </div>
    );
};

type productProps = {
    title?: string;
    description?: string;
    to?: string;
    children?: ReactNode;
    disableButton?: boolean;
    acccessories?: boolean;
}

export function Product({title, description, children, to, disableButton, acccessories}: productProps) {
    return (
        <>
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                paddingLeft: "20px",
                paddingRight: "20px"
            }}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <h1 style={{color: "var(--primary-text-color)"}}>{title}</h1>
                    <label style={{color: "var(--primary-text-color)", fontSize: "22px"}}>{description}</label>
                </div>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    {
                        children
                    }
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    paddingLeft: "20px",
                    paddingRight: "20px"
                }}>
                    <div
                        style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                        {!disableButton && <RedLinkButton name={"OFFERTE AANVRAGEN"} to={to}/>}
                    </div>
                    <div
                        style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                        {acccessories && <RedLinkButton name={"Toebehoren"} to={"/toebehoren"}/>}
                    </div>
                </div>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                height: "10px",
                boxShadow: "inset 0px 0px 2px 0px black",
                marginTop: "10px"
            }}/>
        </>
    )
}