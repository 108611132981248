import Controller, {RequestOptions} from "../controllers/Controller";
import ISystemInformation from "../models/ISystemInformation";

class SystemInformationController extends Controller<ISystemInformation> {
    constructor() {
        super("/api/v1");
    }

    /**
     * Request the record by id.
     */
    async get(): Promise<ISystemInformation | null> {
        try {
            const requestOptions: RequestOptions = {
                method: "GET"
            }
            const response: Response = await this.httpRequest(requestOptions, `/data`);

            if (response.status !== 200)
                return null;

            const json = await response.json();
            return json.data ?? null;
        } catch (e) {
            this.showDebugOutput(e);
            return null;
        }
    }
}

export default new SystemInformationController();