import {JSX, ReactNode} from "react";
import dummyImageSolar from '../assets/rs-solar-thumbnail.png';
import {CheckMarkBoxes} from "../components/CheckMarkBoxes";
import boilerInstallation from "../assets/intallation_boiler1_flow.jpg";
import boiler2Installation from "../assets/intallation_boiler2_flow.jpg";
import wiringNetwork from '../assets/wiring-network.png';
import wirelessNetwork from '../assets/wireless-network.png';
import wiringNetworkHw from '../assets/network-hw.jpg';
import installation1 from "../assets/praktijk1.jpg";
import installation2 from "../assets/praktijk2.jpg";
import installation3 from "../assets/praktijk3.jpg";
import installation4 from "../assets/praktijk4.jpg";
import installation5 from "../assets/praktijk5.jpg";
import fileIcon from "../assets/icon_file.svg";
import {Product} from "./ShopPage";
import {Link} from "react-router-dom";

export default function InstallationPage(): JSX.Element {
    return (
        <div className="main_container">
            <div style={{
                backgroundImage: `url(${dummyImageSolar})`, backgroundSize: "cover", display: "flex",
                flexDirection: "column", justifyContent: "flex-end"
            }}>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        maxWidth: "900px",
                        paddingLeft: "20px"
                    }}>
                        <h1 style={{fontSize: "45px", textShadow: "1px 1px 3px BLACK"}}>Installatie</h1>
                        <label style={{fontSize: "20px", textShadow: "1px 1px 3px BLACK"}}>

                        </label>
                        <div style={{display: "flex", flexDirection: "row"}}>
                        </div>
                    </div>
                </div>
                <CheckMarkBoxes
                    titles={["Bespaar op gasverbruik", "Meer zelfvoorzienend", "Verdien aan zonne energie"]}/>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "20px"
            }}>
                <h2 style={{color: "var(--primary-text-color)"}}>Hoe moet ik het RS-Solar Energy systeem
                    installeren?</h2>
                <article style={{width: "100%", maxWidth: "900px"}}>De meegeleverde boilers zijn uitgevoerd met een
                    eenvoud is waar wij naar streven. Dit trachten wij ook door te zetten in onze producten. Door de
                    installatie van de onderdelen zo simpel mogelijk te houden. Bij onze <b>RS-Solar energy
                        systeem</b> ontvangt u een
                    duidelijke installatie handleiding zodat u eenvoudig zelf de installatie kunt verzorgen. Mocht u er
                    onverhoopt toch niet uitkomen, dan staan onze professionals u graag te woord met raad en daad.
                    <br/><br/>
                    Wij raden het aan om gebruik te maken van een vaste verbinding over Ethernet.
                    <h3 style={{color: "black"}}>Voorbeelden installaties:</h3>
                    <ul>
                        <li>Enkele boiler</li>
                        <li>Meerdere boilers</li>
                        <li>Netwerk</li>
                    </ul>
                    <div style={{fontFamily: ""}}></div>
                    <ul></ul>
                </article>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                height: "10px",
                boxShadow: "inset 0px 0px 2px 0px black",
                marginTop: "10px"
            }}/>

            <Product title="Enkele boiler" disableButton>
                <div style={{width: "100%", maxWidth: "900px"}}>
                    De boiler dien je in serie met de Cv-installatie te monteren, zoals aangegeven op het voorbeeld
                    hieronder:
                </div>
                <img style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    borderRadius: "10px",
                    width: "100%",
                    maxWidth: "900px"
                }} src={boilerInstallation} alt=""/>
            </Product>

            <Product title="Meerdere boilers" disableButton>
                <div style={{width: "100%", maxWidth: "900px"}}>
                    Maak je gebruik van meerdere boilers, dan is het raadzaam om de boiler met het kleinste inhoud aan
                    te sluiten aan de inlaat van de Cv-installatie.
                    Hieronder een voorbeeld van zo'n installatie:
                </div>
                <img style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    borderRadius: "10px",
                    width: "100%",
                    maxWidth: "900px"
                }} src={boiler2Installation} alt=""/>
            </Product>

            <Product title="Netwerk" disableButton>
                <div style={{width: "100%", maxWidth: "900px"}}>
                    Om de <b>HomeWizard-APP</b> te gebruiken met het <b>RS-Solar energy systeem</b>, dient u de
                    bekabeling als volgt
                    aan te
                    leggen: Zorg ervoor dat alle aansluitingen correct zijn verbonden, zodat het <b>RS-Solar energy
                    systeem</b> de
                    juiste gegevens kan uitlezen. Wanneer de installatie correct is uitgevoerd, kunt u via de
                    <b>HomeWizard-APP</b> eenvoudig de gegevens van uw systeem inzien en monitoren.
                    <br/>
                    <br/>
                    De <b>HomeWizard-APP</b> biedt een overzicht
                    van alle relevante informatie om uw zonne-energiegebruik optimaal te beheren.
                </div>
                <img style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    borderRadius: "10px",
                    width: "100%",
                    maxWidth: "900px"
                }} src={wiringNetworkHw} alt=""/>
                <div style={{width: "100%", maxWidth: "900px"}}>
                    <h1 style={{color: "var(--primary-text-color)", textAlign: "center"}}>Bekabeld</h1>
                    Voor een bekabelde <b>RS-Solar energy systeem</b> is het vereist dat de <b>RS-P1 meter</b> in het
                    zelfde netwerk beschikbaar is. Als dit het geval is, en beide systemen krijgen een IP-adres toegekend
                    door de DHCP-server (in de zelfde subnet-range), zouden ze elkaar moeten vinden.
                </div>
                <img style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    borderRadius: "10px",
                    width: "100%",
                    maxWidth: "900px"
                }} src={wiringNetwork} alt=""/>
                <div style={{width: "100%", maxWidth: "900px"}}>
                    <h1 style={{color: "var(--primary-text-color)", textAlign: "center"}}>Draadloos</h1>
                    Het draadloze <b>RS-Solar energy systeem</b> maakt het mogelijk om rechtstreeks via een WiFi-signaal
                    verbinding te maken met de <b>RS-P1 meter</b>.
                    Het werkt autonoom, wat betekent dat het <u>geen</u> verbinding maakt met het WiFi-netwerk van de
                    huisinstallatie, maar los daarvan functioneert.
                    Dit systeem biedt een handige oplossing voor het gebruik van de sensor zonder afhankelijk te zijn
                    van een bekabelde internetverbinding of het thuisnetwerk.
                    <br/><br/>
                    Aangezien WiFi meer stroom vereist dan een bekabelde verbinding, wordt er aangeraden om de <b>RS-P1
                    meter</b> te voeden via de Micro-USB-B port. Om te zorgen dat de <b>RS-P1 meter</b> de Wi-Fi
                    accesspoint
                    start
                    mag de <b>RS-P1 meter</b> tijdens het starten <u>niet aangesloten</u> zijn aan een bekabeld netwerk.
                </div>
                <img style={{
                    marginLeft: "20px",
                    marginRight: "20px",
                    borderRadius: "10px",
                    width: "100%",
                    maxWidth: "900px"
                }} src={wirelessNetwork} alt=""/>
            </Product>

            <Product title="Documentatie" disableButton>
                <div style={{width: "100%", maxWidth: "900px"}}>
                    <table>
                        <tbody>
                        <tr>
                            <td><img src={fileIcon} style={{width: "45px"}}/></td>
                            <td><b>Handleiding Solar</b><br/>Handleiding RS-Solar Energy<br/>
                                <Link style={{color: "var(--main-background-color)", fontWeight: "bold"}}
                                      target="_blank"
                                      to={"/resources/Handleiding.pdf"} download>Handleiding.pdf (2.46MB)</Link>
                            </td>
                        </tr>
                        <tr>
                            <td><img src={fileIcon} style={{width: "45px"}}/></td>
                            <td><b>Handleiding Solar</b><br/>Handleiding RS-Solar Energy<br/>
                                <Link style={{color: "var(--main-background-color)", fontWeight: "bold"}}
                                      target="_blank"
                                      to={"/resources/10 Schakelkast RS-Solar energy Montage tekening.PDF"} download>
                                    10 Schakelkast RS-Solar energy Montage tekening.PDF (52.06KB)
                                </Link></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </Product>

            <Product title="Prijktijk voorbeelden" disableButton>
                <div style={{
                    width: "100%",
                    maxWidth: "900px",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row"
                }} className={"check-marks"}>
                    <img style={{
                        margin: "20px",
                        borderRadius: "10px",
                        width: "100%",
                        maxWidth: "400px",
                        boxShadow: "rgba(0, 0, 0, 0.23) 0px 0px 7px 0px"
                    }} src={installation5} alt=""/>
                    <img style={{
                        margin: "20px",
                        borderRadius: "10px",
                        width: "100%",
                        maxWidth: "400px",
                        boxShadow: "rgba(0, 0, 0, 0.23) 0px 0px 7px 0px"
                    }} src={installation2} alt=""/>
                </div>
                <div style={{
                    width: "100%",
                    maxWidth: "900px",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row"
                }} className={"check-marks"}>
                    <img style={{
                        margin: "20px",
                        borderRadius: "10px",
                        width: "100%",
                        maxWidth: "400px",
                        boxShadow: "rgba(0, 0, 0, 0.23) 0px 0px 7px 0px"
                    }} src={installation3} alt=""/>
                    <img style={{
                        margin: "20px",
                        borderRadius: "10px",
                        width: "100%",
                        maxWidth: "400px",
                        boxShadow: "rgba(0, 0, 0, 0.23) 0px 0px 7px 0px"
                    }} src={installation4} alt=""/>
                </div>
                <div style={{
                    width: "100%",
                    maxWidth: "900px",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row"
                }} className={"check-marks"}>
                    <img style={{
                        margin: "20px",
                        borderRadius: "10px",
                        width: "100%",
                        maxWidth: "400px",
                        boxShadow: "rgba(0, 0, 0, 0.23) 0px 0px 7px 0px"
                    }} src={installation1} alt=""/>
                </div>
            </Product>
        </div>
    );
};