export interface IController<T> {
    getAll(): Promise<T[] | null>;
    getById(id: number): Promise<T | null>;
    create(data: T): Promise<T | null>;
    delete(id: number): Promise<T | null>;
    update(id: number, data: T): Promise<T | null>;
}

/**
 * Options that can be used for requesting data.
 */
export type RequestOptions = {
    method: "PUT" | "DELETE" | "GET" | "POST";
    headers?: { [key: string]: string };
    body?: string | FormData;
}

export default abstract class Controller<T> implements IController<T> {
    protected debugOutput: boolean = false;
    protected url: string;
    protected path: string;

    protected constructor(path: string, url: string = ``) {
    //protected constructor(path: string, url: string = `http://192.168.2.44:3021`) {
    //protected constructor(path: string, url: string = `http://192.168.2.73:3021`) {
        this.url = url;
        this.path = path;
    }

    /**
     * Request all the records from the API.
     */
    async getAll(): Promise<T[] | null> {
        const requestOptions: RequestOptions = {
            method: "GET"
        }
        const response: Response = await this.httpRequest(requestOptions, `/all`);

        if (response.status !== 200)
            return null;

        const json = await response.json();
        return json.data ?? null;
    }

    /**
     * Request the record by id.
     * @param id
     */
    async getById(id: number): Promise<T | null> {
        try {
            const requestOptions: RequestOptions = {
                method: "GET"
            }
            const response: Response = await this.httpRequest(requestOptions, `/all`);

            if (response.status !== 200)
                return null;

            const json = await response.json();
            return json.data ?? null;
        } catch (e) {
            this.showDebugOutput(e);
            return null;
        }
    }

    /**
     * Creates the record at the API.
     * @param data
     */
    async create(data: T): Promise<T | null> {
        try {
            const requestOptions: RequestOptions = {
                method: "POST",
                body: JSON.stringify(data)
            }
            const response: Response = await this.httpRequest(requestOptions);

            if (response.status !== 200)
                return null;

            const json = await response.json();
            return json.data ?? null;
        } catch (e) {
            this.showDebugOutput(e);
            return null;
        }
    }

    /**
     * Deletes the record at the API based on the id.
     * @param id
     */
    async delete(id: number): Promise<T | null> {
        try {
            const requestOptions: RequestOptions = {
                method: "DELETE"
            }

            const response: Response = await this.httpRequest(requestOptions, `/${id}`);

            if (response.status !== 200)
                return null;

            const json = await response.json();
            return json.data ?? null;
        } catch (e) {
            this.showDebugOutput(e);
            return null;
        }
    }

    /**
     * Updates the requested record with the given data.
     * @param id
     * @param data
     */
    async update(id: number, data: T): Promise<T | null> {
        try {
            const requestOptions: RequestOptions = {
                method: "PUT",
                body: JSON.stringify(data)
            }

            const response: Response = await this.httpRequest(requestOptions, `/${id}`);

            if (response.status !== 200)
                return null;

            const json = await response.json();
            return json.data ?? null;

        } catch (e) {
            this.showDebugOutput(e);
            return null;
        }
    }

    /**
     * Used for requesting data at the API.
     * @param requestData
     * @param subPath
     * @protected
     */
    protected async httpRequest(requestData: RequestOptions, subPath: string = ""): Promise<any> {
        try {
            const response = await fetch(`${this.url}${this.path}${subPath}`, {
                headers: {
                    'Content-type': 'application/json; charset=UTF-8'
                },
                ...requestData,
            });

            if (this.debugOutput)
                console.log("CODE", response.status, "URL: ", `${this.url}${this.path}${subPath}`, "RESPONSE: ", response);

            return response;
        } catch (e) {
            this.showDebugOutput(e);
            return null;
        }
    }

    protected showDebugOutput(error: unknown): void {
        if (this.debugOutput)
            console.error(error);
    }
}